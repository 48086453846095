import React, { useState }  from "react";
import Select from "react-select";
import { Row, Col, Card, CardBody, CardTitle,Input, Label,Button } from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb";
import "./Tables/datatables.scss";

import * as yup from 'yup';
import { Formik,Form } from 'formik';
const schema = yup.object().shape({
  country: yup.string().required("Country must not empty"),
 
});
const Country = () => {
  const [selectedGroup, setselectedGroup] = useState<any>(null);
  const [selectedOptions, setselectedOptions] = useState<any>(null);
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
    },
    {
      dataField: "addedby",
      text: "Added By",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
   
  ];

  // Table Data
  const productData = [
    {
      id: 1,
      name: "United States",
      code: "US",
      currency: "$",
      addedby: "Amit",
      date: "2008/11/28",
      
    },

    {
      id: 2,
      name: "India",
      code: "IN",
      currency: "Rs",
      addedby: "Mayank",
      date: "2009/10/09",
     
    }

  
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow: any = {
    mode: "checkbox",
  };

  function handleSelectGroup(selectedGroup: any) {
    setselectedGroup(selectedGroup);
  }

  
  const optionGroup = [
    {
      label: "Country",
      options: [
        { label: "United States", value: "1" },
        { label: "India", value: "2" },
      
      ],
    },
    
  ];

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Country On Board | iRAN</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tables" breadcrumbItem="Country On Board" />
          <Row>
          <Card>
                <CardBody>
                  <CardTitle className="h4">Add Country </CardTitle>
                <Col lg={6}>
                <Formik
      validationSchema={schema}
       onSubmit={async (values, {setErrors}) => {
		  // alert(JSON.stringify(values, null, 2));
	   }}
      initialValues={{
        country: '',
       
       
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-4">
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label"
                >
                  Country
                </Label>
                <Col sm={9}>
                <select
                className="form-control"
                name="country"
                id="country"
                onChange={handleChange}
                
              >
                <option value="">Select-</option>
              <option value="1">USA</option>
                <option value="2">Canada</option>
                <option value="3">UAE</option>
                <option value="4">South Africa</option>
                <option value="5">Maxico</option>
                <option value="6">Australia</option>
                <option value="7">Qatar</option>
             
                </select>
               <div className="invalid-feedback" style={{ display: errors.country ? "block" : "none" }}>
                            {errors.country}
                          </div>
                </Col>
               
              </Row>
              <Row className="justify-content-end">
                <Col sm={9}>
                

                  <div>
                    <button type="submit" className="btn btn-primary w-md">
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>

             
              </Form>
                      )}
                      </Formik>
                </Col>
               
                </CardBody>
                </Card>
            </Row>       
               
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Country List </CardTitle>
                 

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    // columns={columns}
                    // data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Country;
