import React from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";

//import images
import logo from "../../assets/images/integer-logo.png";
import CarouselPage from "./CarouselPage";
import * as yup from 'yup';
import { Formik,Form } from 'formik';

const schema = yup.object().shape({
  firstName: yup.string().min(2, 'Too Short!').required("First name must not empty"),
  lastName: yup.string().required("Last name must not empty"),
  email: yup.string().required("Email must not empty").email(),
  password: yup.string().required("Password must not empty"),
  mobile: yup.number().required("Mobile must not empty"),
 // state: yup.string().required(),
 // zip: yup.string().required(),
 // terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
});
const PageRegister = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | i-RAN</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-4 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="40" />{" "}
                        {/*<span className="logo-txt">iRAN</span>*/}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">
                          Get your iRAN account now.
                        </p>
                      </div>
                      <Formik
      validationSchema={schema}
       onSubmit={async (values, {setErrors}) => {
		  // alert(JSON.stringify(values, null, 2));
	   }}
      initialValues={{
        firstName: '',
        lastName: '',
       email:'',
	   password:'',
        mobile: '',
       
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
					  
					   <div className="mb-3">
                          <label htmlFor="firstname" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
							name="firstName"
							 onChange={handleChange}
                            id="firstName"
                            placeholder="Enter firstname"
							
                            required
                          />
                          <div className="invalid-feedback" style={{ display: errors.firstName ? "block" : "none" }}>
                           {errors.firstName}
                          </div>
                        </div>
						 <div className="mb-3">
                          <label htmlFor="lastname" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
							name="lastName"
							 onChange={handleChange}
                            id="lastName"
                            placeholder="Enter lastname"
							
                            required
                          />
                          <div className="invalid-feedback" style={{ display: errors.lastName ? "block" : "none" }}>
                           {errors.lastName}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
							name="email"
                            id="email"
							 onChange={handleChange}
                            placeholder="Enter email"
							
                            required
                          />
                          <div className="invalid-feedback" style={{ display: errors.email ? "block" : "none" }}>
                             {errors.email}
                          </div>
                        </div>

                       

                        <div className="mb-3">
                          <label htmlFor="userpassword" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
							id="validationpassword"
                            placeholder="Enter password"
							 onChange={handleChange}
							
                            required
                          />
                          <div className="invalid-feedback" style={{ display: errors.password ? "block" : "none" }}>
                            {errors.password}
                          </div>
                        </div>
						
						 <div className="mb-3">
                          <label htmlFor="mobile" className="form-label">
                            Mobile
                          </label>
                          <input
                            type="text"
                            className="form-control"
							name="mobile"
                            id="mobile"
							 onChange={handleChange}
                            placeholder="Enter mobile"
							
                            required
                          />
                          <div className="invalid-feedback"  style={{ display: errors.mobile ? "block" : "none" }}>
                             {errors.mobile}
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="mb-0">
                            By registering you agree to the Minia{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </Form>
  )}
    </Formik>
                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{" "}
                          <Link
                            to="/page-login"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            Login{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©{" "}
                        <script>
                          document.write(new Date().getFullYear())
                        </script>{" "}
                       
                        <i className="mdi mdi-heart text-danger"></i> by
                        Integer Telecom
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PageRegister;
