import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

import logo from "../../assets/images/integer-logo.png";
import CarouselPage from "./CarouselPage";
import * as yup from 'yup';
import { Formik,Form } from 'formik';

const schema = yup.object().shape({
  email: yup.string().required("Email must not empty").email(),
  password: yup.string().required("Password must not empty"), 
});
const PageLogin = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | iRAN - Integertel</title>
      </MetaTags>
      <div className="auth-page">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xxl-3 col-lg-4 col-md-5">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-4 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                      <img src={logo} alt="" height="40" />{" "}
                        {/*<span className="logo-txt">iRAN</span>*/}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue to iRAN.
                        </p>
                      </div>
                      <Formik
      validationSchema={schema}
       onSubmit={async (values, {setErrors}) => {
		  // alert(JSON.stringify(values, null, 2));
	   }}
      initialValues={{
      
       email:'',
	   password:'',
      
       
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
							              onChange={handleChange}
                            placeholder="Enter email"
                          />
                           <div className="invalid-feedback" style={{ display: errors.email ? "block" : "none" }}>
                             {errors.email}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Password</label>
                            </div>
                          
                          </div>

                          <div className="input-group auth-pass-inputgroup">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter password"
                              aria-label="Password"
                              aria-describedby="password-addon"
                              name="password"
							               
                             onChange={handleChange}
                            />
                            <button
                              className="btn btn-light ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          <div className="invalid-feedback" style={{ display: errors.password ? "block" : "none" }}>
                            {errors.password}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                        </Form>
                      )}
                      </Formik>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Don't have an account ?{" "}
                          <Link
                            to="/page-register"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            Signup now{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        ©
                        <i className="mdi mdi-heart text-danger"></i> by Integer Telecom
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CarouselPage />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageLogin;
